import React, { PureComponent } from 'react';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { withApollo } from 'react-apollo';
import _ from 'lodash';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import PageWrapper from './partialComponents/PageWrapper';
import Testimonials from './partialComponents/Testimonials';
import AssetFeature from './partialComponents/AssetFeature';
import ProductSummary from './partialComponents/ProductSummary';
import ProductCards from './partialComponents/ProductCards';
// import Slider from '../components/Slider';
import RegisterBroker from './partialComponents/RegisterBroker';
import Partners from './partialComponents/Partners';
// import Contactus from './partialComponents/Contactus';

// import ViewEmptyDetect from '../components/Fade/EmptyDetect';

// import Modal from '../components/Modal';
import { Modal } from 'react-bootstrap';
import Faq from './Faq';
import PrivacyPolicy from './PrivacyPolicy';
import NewsAndPress from './AboutUs/newsAndPress';
// import AboutUs from './AboutUs';
import TermsAndCondition from './TermsAndCondition';
import LandingCover from './LandingCover';
import { COIN_META_DATA } from './defaults';
import { read } from '../runtimeConfig';

import { makeDashboardUri } from '../utils';
import * as marketingEvents from '../marketingEvents';
import { FaqByPage } from './Faq';

const AboutUs = React.lazy(() => import('./AboutUs'));
const SMB = React.lazy(() => import('./SMB'));
const CryptoShield = React.lazy(() => import('./CryptoShield'));
const CryptoShieldPro = React.lazy(() => import('./CryptoShieldPro'));
const Unchained = React.lazy(() => import('./Unchained'));
const PartnersPage = React.lazy(() => import('./Partners'));
const Services = React.lazy(() => import('./Services'));
const Blogs = React.lazy(() => import('./Blogs'));
const WalletAssessment = React.lazy(() => import('./WalletAssessment'));
const EmbeddedAPI = React.lazy(() => import('./EmbeddedAPI'));
const CyberInsurance = React.lazy(() => import('./CyberInsurance'));
const DirectorsOfficers = React.lazy(() => import('./DirectorsOfficers'));
const Kresus = React.lazy(() => import('./Kresus'));
const Kinto = React.lazy(() => import('./Kinto'));
const ProductDemo = React.lazy(() => import('./partialComponents/ProductDemo'));
const ContactUsTally = React.lazy(() =>
  import('./CyberInsurance/contactUsTally'),
);

const CONFIG = window.__CONFIG__ || {};
class Landing extends PureComponent {
  state = {
    currentCurrencyValue: '1.23',
    currentCurrencyCode: 'BTC',
    userEdits: false,
    card1: false,
    card2: false,
    showVideoPopup: false,
    promoText: null,
    // card1Phase2: false,
    isDashboardLive: false,
    fetchingConfig: true,
    cyberPageLanding: CONFIG.landing,
  };

  componentDidMount() {
    this.fetchConfig();
    this.initializeGoogleTag();
  }

  initializeGoogleTag() {
    const { cyberPageLanding } = this.state;
    const gtmId =
      cyberPageLanding === 'cyber' ? 'AW-16507107929' : 'AW-10817897197';

    TagManager.initialize({
      gtmId,
    });
    const tagManagerArgs = {
      dataLayer: {
        js: new Date(),
      },
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  fetchConfig = () => {
    const isHeadless = window.location.pathname.includes('headless');
    if (isHeadless) {
      return;
    }

    read('dashboardLive')
      .then(
        isDashboardLive =>
          typeof isDashboardLive == 'boolean' &&
          this.setState({ isDashboardLive }),
      )
      // eslint-disable-next-line no-console
      .catch(err =>
        console.error(`Error initialising dashboardLive: ${err.message}`),
      )
      .finally(() => {
        this.setState({ fetchingConfig: false });
      });
    read('adTrackKeys').then(marketingEvents.register);
    read('beaconMarketingKeys').then(keys => {
      const targetKeys = typeof keys == 'object' ? keys : {};
      this.setState({
        beaconKeys: targetKeys,
      });
    });
    read('promoText').then(text => this.setState({ promoText: text }));
  };

  changeCurrency = value => {
    let newCurrencyValue = this.state.currentCurrencyValue;
    if (!this.state.userEdits)
      newCurrencyValue = _.get(COIN_META_DATA[value.code], 'demoQuantity', 0);

    this.setState({
      currentCurrencyValue: newCurrencyValue,
      currentCurrencyCode: value.code,
    });
  };

  handleChangeCurrencyValue = e => {
    if (e.target.value.length <= e.target.maxLength)
      this.setState({
        userEdits: true,
        currentCurrencyValue: e.target.value,
      });
  };

  handleGetStated = () => {
    const { carousalApi, isDashboardLive } = this.state;
    if (isDashboardLive) {
      const dashboardUri = makeDashboardUri(window.origin);
      return (window.location = `${dashboardUri}cryptocurrency-insurance-quick-quote`);
    }
    marketingEvents.waitList();
    carousalApi.handleShowCountry();
  };

  handleGetAQuote = () => {
    const { isDashboardLive } = this.state;
    if (isDashboardLive) {
      const dashboardUri = makeDashboardUri(window.origin);
      return (window.location = `${dashboardUri}cryptocurrency-insurance-quick-quote`);
    } else {
      alert('Dashboard not online');
    }
  };

  handleRedirection = key => {
    const dashboardUri = makeDashboardUri(window.origin);
    return (window.location = `${dashboardUri}${key}`);
  };

  selectAll = e => {
    e.target.select();
  };

  renderVideoPopUp = () => {
    const { showVideoPopup } = this.state;
    return (
      <Modal
        show={showVideoPopup}
        size="xl"
        backdrop="static"
        onHide={() => this.setState({ showVideoPopup: false })}
      >
        <div>
          <div
            className="position-absolute"
            style={{ right: -5, top: 5, zIndex: 1, opacity: 0.5 }}
          >
            <button
              className="btn text-black btn-link"
              href="#"
              onClick={() => this.setState({ showVideoPopup: false })}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div style={{ minHeight: '30vh' }}>
            <ProductDemo />
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    const {
      isDashboardLive,
      promoText,
      beaconKeys,
      cyberPageLanding,
    } = this.state;

    if (cyberPageLanding === 'cyber') {
      return (
        <PageWrapper hideMenu>
          <EmbeddedAPI />
          {/* <Contactus /> */}
          <ContactUsTally />
        </PageWrapper>
      );
    }

    return (
      <Router>
        <Switch>
          <Route path="/product-summary-headless">
            <ProductSummary detailOnly />
          </Route>
          <Route path="/faq-headless">
            <Faq />
          </Route>
          <Route path="/faq">
            <PageWrapper isDashboardLive={isDashboardLive}>
              <Faq />
            </PageWrapper>
          </Route>
          <Route path="/privacy-policy">
            <PageWrapper isDashboardLive={isDashboardLive}>
              <PrivacyPolicy />
            </PageWrapper>
          </Route>
          <Route path="/terms-conditions">
            <PageWrapper isDashboardLive={isDashboardLive}>
              <TermsAndCondition />
            </PageWrapper>
          </Route>
          <Route path="/about-us">
            <PageWrapper isDashboardLive={isDashboardLive}>
              <AboutUs beaconKeys={beaconKeys} />
              <FaqByPage page="aboutus" />
            </PageWrapper>
          </Route>
          <Route path="/cryptoshield">
            <PageWrapper
              isDashboardLive={isDashboardLive}
              promoText={promoText}
            >
              <CryptoShield beaconKeys={beaconKeys} />
              <ContactUsTally />
              <FaqByPage page="individuals" />
            </PageWrapper>
          </Route>
          <Route path="/cryptoshieldpro">
            <PageWrapper isDashboardLive={isDashboardLive}>
              <CryptoShieldPro beaconKeys={beaconKeys} />
              <ContactUsTally />
              <FaqByPage page="institutions" />
            </PageWrapper>
          </Route>
          <Route path="/unchained">
            <PageWrapper isDashboardLive={isDashboardLive}>
              <Unchained beaconKeys={beaconKeys} />
              <ContactUsTally />
              <FaqByPage page="institutions" />
            </PageWrapper>
          </Route>
          <Route path="/kresus">
            <PageWrapper isDashboardLive={isDashboardLive}>
              <Kresus beaconKeys={beaconKeys} />
            </PageWrapper>
          </Route>
          <Route path="/kinto">
            <PageWrapper isDashboardLive={isDashboardLive}>
              <Kinto beaconKeys={beaconKeys} />
            </PageWrapper>
          </Route>
          <Route path="/smb">
            <PageWrapper isDashboardLive={isDashboardLive}>
              <SMB beaconKeys={beaconKeys} />
              <ContactUsTally />
              <FaqByPage page="smbs" />
            </PageWrapper>
          </Route>
          <Route path="/wallet-assessment">
            <PageWrapper isDashboardLive={isDashboardLive}>
              <WalletAssessment />
              <ContactUsTally />
            </PageWrapper>
          </Route>
          <Route path="/cyber-insurance">
            <PageWrapper isDashboardLive={isDashboardLive}>
              <CyberInsurance />
              <ContactUsTally />
            </PageWrapper>
          </Route>
          <Route path="/directors-officers">
            <PageWrapper isDashboardLive={isDashboardLive}>
              <DirectorsOfficers />
              <ContactUsTally />
            </PageWrapper>
          </Route>
          <Route path="/cryptoshieldproplatforms">
            <PageWrapper isDashboardLive={isDashboardLive}>
              <EmbeddedAPI />
              <ContactUsTally />
            </PageWrapper>
          </Route>
          <Route path="/services">
            <PageWrapper isDashboardLive={isDashboardLive}>
              <Services beaconKeys={this.state.beaconKeys} />
              <ContactUsTally />
              <FaqByPage page="services" />
            </PageWrapper>
          </Route>
          <Route path="/blogs">
            <PageWrapper isDashboardLive={isDashboardLive}>
              <Blogs />
            </PageWrapper>
          </Route>
          <Route path="/partners">
            <PageWrapper isDashboardLive={isDashboardLive}>
              <PartnersPage />
              <FaqByPage page="partners" />
            </PageWrapper>
          </Route>
          <Route path="/">
            <PageWrapper isDashboardLive={isDashboardLive}>
              <Helmet>
                <title>Breach Insurance | Regulated Crypto Insurance</title>
                <meta
                  name="description"
                  content="Breach Insurance is a specialty underwriting company that understands emerging risks, including crypto, fintech, and other difficult to underwrite industries."
                />
              </Helmet>

              <LandingCover beaconKeys={beaconKeys} />
              {this.renderVideoPopUp()}
              <div ref={ref => (this.productSummaryRef = ref)}>
                <ProductCards />
              </div>
              <RegisterBroker />

              <div className="position-relative">
                <AssetFeature />
                {/* <Statistics /> */}

                <Testimonials />

                <Partners />
                <NewsAndPress />
                {/* <div className="landing-bg" /> */}
              </div>

              <ContactUsTally />
              <FaqByPage page="home" />
            </PageWrapper>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default withApollo(Landing);
